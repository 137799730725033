// this.API_URL = "http://localhost:7778/";
// this.SUCCESS_URL = "http://localhost:8080/seelfa-frontend/#/thankyou/";
// this.API_URL = "http://67.205.148.222:7778/";
// this.SUCCESS_URL = "http://67.205.148.222/seelfa-frontend/#/thankyou/";
// this.API_URL = 'https://staging.seelfa.com/api/';
// this.SUCCESS_URL = "https://staging.seelfa.com/seelfa-frontend/#/thankyou/";
// this.API_URL = 'https://localhost:3000/';
// this.API_URL = 'https://seelfa-2-api-dev-env.eba-jj32v9fm.us-east-2.elasticbeanstalk.com/';
// this.API_URL = "https://us-central1-seelfa-206f0.cloudfunctions.net/";
export const NODE_ENV = process.env.NODE_ENV || "";
export const VUE_APP_MODE = process.env.VUE_APP_MODE || "";
export const API_URL = process.env.VUE_APP_API_URL || "";
export const STRIPE_PUBLISHABLE_KEY =
  process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY || "";
export const STRIPE_MODE = process.env.VUE_APP_STRIPE_MODE || "test";
export const STRIPE_TEST_MODE = process.env.VUE_APP_STRIPE_MODE != "live";
console.log("NODE_ENV", NODE_ENV);
console.log("VUE_APP_MODE", VUE_APP_MODE);
console.log("API_URL", API_URL);
console.log("STRIPE_MODE", STRIPE_MODE);
console.log("STRIPE_TEST_MODE", STRIPE_TEST_MODE);
